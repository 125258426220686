.randomizer {
  position: relative;
  height: 100%;

  .randomizerLink {
    position: relative;
    display: block;
    height: 100%;
    transform: scale3d(1,1,1);
    transition: .15s ease-out transform;
    z-index: 2;

    div {
      height: 100%;
    }

    &:hover, &:focus {
      transform: scale3d(1.05, 1.05, 1);
    }
  }

  .randomizerImage {
    height: 100%;
  }
}
