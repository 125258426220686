@import '@/styles/utils/mixins/animations';
@import '@/styles/utils/functions/rem-calc';
@import '@/styles/utils/mixins/aspect-ratio';
@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/mixins/shadows';

.moduleWrapper {
  --pbsk-component-masthead-lookinside-game-preview-card-border-radius: 12px;
  --pbsk-component-masthead-lookinside-game-preview-card-border-width: 5px;
  --pbsk-component-masthead-lookinside-game-preview-container-height: 39vw;
  --pbsk-component-masthead-lookinside-game-preview-info-width: 40%;
  --pbsk-component-masthead-lookinside-game-preview-logo-wrap-width: 80%;
  --pbsk-component-masthead-lookinside-game-preview-play-button-border-radius: 10px;
  --pbsk-component-masthead-lookinside-game-preview-play-button-font-size: 1.125rem;
  --pbsk-component-masthead-lookinside-game-preview-pagination-height: 6.75%;

  max-width: $breakpoint-lg;
  margin: 0 auto;
  padding: 0 var(--pbsk-component-masthead-lookinside-game-preview-card-border-width) 24px;

  @include breakpoint(sm) {
    --pbsk-component-masthead-lookinside-game-preview-container-height: 25.75vw;
    --pbsk-component-masthead-lookinside-game-preview-info-width: 100%;
    --pbsk-component-masthead-lookinside-game-preview-logo-width: 90%;
    --pbsk-component-masthead-lookinside-game-preview-play-button-font-size: 1.3125rem;

    display: grid;
    grid-template-columns: 1fr 28%;
    gap: 30px;
  }

  @include breakpoint(md) {
    --pbsk-component-masthead-lookinside-game-preview-card-border-radius: 15px;
    --pbsk-component-masthead-lookinside-game-preview-card-border-width: 8px;
  }

  @include breakpoint(lg) {
    --pbsk-component-masthead-lookinside-game-preview-play-button-border-radius: 16px;
    --pbsk-component-masthead-lookinside-game-preview-play-button-font-size: 2.1875rem;
    --pbsk-component-masthead-lookinside-game-preview-pagination-height: 5.5%;

    grid-template-columns: 1fr 30%;
    gap: 40px;
    padding-bottom: 30px;
  }
}

.gameVisuals {
  display: grid;
  justify-items: center;
  grid-template-rows: 1fr max-content;
  width: 100%;

  .paginationWrapper {
    width: var(--pbsk-component-masthead-lookinside-game-preview-pagination-height);
    max-width: 32px;
    padding-bottom: var(--pbsk-component-masthead-lookinside-game-preview-pagination-height);

    ul {
      position: absolute;
    }
  }

  [data-pbsk-component-carousel] {
    left: 0;
    width: 100%;

    & > div {
      padding-bottom: 3.25%;
    }

    ul {
      height: var(--pbsk-component-masthead-lookinside-game-preview-container-height);
      max-height: 285px;
    }
  }

  @include breakpoint(sm) {
    margin-bottom: 0;

    [data-pbsk-component-carousel] {
      --slide-size: 62%;
    }
  }
}

.gameInfo {
  display: none;
  width: var(--pbsk-component-masthead-lookinside-game-preview-info-width);
  max-width: 300px;
  margin: 0 auto;
  font-family: var(--pbsk-font-family-headline);

  @include breakpoint(sm) {
    display: grid;
    grid-template-rows: 1fr max-content;
  }
}

.gameLogoWrap {
  align-self: flex-end;
  display: block;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;

  img {
    display: block;
    object-fit: contain;
    pointer-events: none;
  }
}

.gameTitleFallback {
  align-self: center;
  padding: 0 var(--pbsk-spacing-1) var(--pbsk-spacing-3);
  font-size: var( --pbsk-component-masthead-lookinside-game-preview-play-button-font-size);
  line-height: 1.25;
  text-align: center;
}

.playButton {
  display: block;
  width: 85%;
  max-width: 230px;
  margin: 0 auto;
  padding: var(--pbsk-spacing-1);
  font-size: var( --pbsk-component-masthead-lookinside-game-preview-play-button-font-size);
  text-transform: uppercase;
  text-align: center;
  border-radius: var(--pbsk-component-masthead-lookinside-game-preview-play-button-border-radius);
  color: var(--pbsk-theme-uiLabelColor);
  background: var(--pbsk-theme-uiControlColor);
}

.carouselBlock {
  width: 100%;
  cursor: pointer;

  [data-pbsk-component-carousel-slide-asset-wrap] {
    position: relative;
    overflow: hidden;
    border-radius: var(--pbsk-component-masthead-lookinside-game-preview-card-border-radius);

    @include standardTransition($properties: box-shadow);
    @include box-shadow-border(#fff, var(--pbsk-component-masthead-lookinside-game-preview-card-border-width));
    @include aspect-ratio(454, 255); // mezzanine aspect ratio

    img {
      object-fit: cover;
    }
  }

  [data-pbsk-component-carousel-slide]:hover {
    [data-pbsk-component-carousel-slide-asset-wrap] {
      @include box-shadow-border(
        var(--pbsk-theme-uiHighlightColor),
        var(--pbsk-component-masthead-lookinside-game-preview-card-border-width)
      );
    }
  }

  &:focus {
    outline: none;

    [data-pbsk-component-carousel-slide-active='true'] [data-pbsk-component-carousel-slide-asset-wrap] {
      @include box-shadow-border(
        var(--pbsk-theme-uiHighlightColor),
        var(--pbsk-component-masthead-lookinside-game-preview-card-border-width)
      );
    }
  }
}
