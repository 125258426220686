@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/functions/rem-calc';

.gamesGrid {
  position: relative;
}

.gamesList {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: 12px;

  @include breakpoint(sm) {
    gap: 16px;
  }

  @include breakpoint(md) {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  @include breakpoint(lg) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.iconHeaderWrapper {
  display: flex;
  align-items: center;
  column-gap: rem-calc(8);
  padding-bottom: rem-calc(12);

  h2 {
    font-size: rem-calc(20);
  }

  .iconStyling {
    width: rem-calc(40);
  }

  @include breakpoint(sm) {
    .iconStyling {
      width: rem-calc(60) !important;
    }
  }

  @include breakpoint(lg) {
    column-gap: rem-calc(12);
    padding-bottom: rem-calc(20);

    h2 {
      font-size: rem-calc(32);
    }
  }
}
