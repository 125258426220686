@import '@/styles/utils/mixins/animations';
@import '@/styles/utils/mixins/breakpoints';

.baseStyles {
  position: relative;
  background-color: var(--pbsk-color-black);
  opacity: 0.5;
  width: 46px;
  height: 116px;

  &[disabled] {
    opacity: 0.5;
  }

  @include breakpoint(sm) {
    background-color: var(--pbsk-theme-uiControlColor);
    opacity: 1;
    border-radius: 16px;
    width: 48px;
    height: 115px;
  }

  @include breakpoint(lg) {
    width: 60px;
    height: 115px;
  }

  @include breakpoint(xl) {
    width: 75px;
    height: 144px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:not(.edgeStyles) {
    border-radius: 16px;
  }
}

.edgeStyles {
  @include breakpoint(sm) {
    width: 42px;
    height: 70px;
  }

  @include breakpoint(lg) {
    width: 60px;
    height: 112px;
  }

  @include breakpoint(xl) {
    border-radius: 16px;
  }
}

.edgeStylesRight {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  @include breakpoint(sm) {
    border-radius: 16px 0 0 16px;
  }

  @include breakpoint(lg) {
    border-radius: 16px;
  }
}

.edgeStylesLeft {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  @include breakpoint(sm) {
    border-radius: 0 16px 16px 0;
  }

  @include breakpoint(lg) {
    border-radius: 16px;
  }
}

.iconBase {
  width: 18px;
  fill: var(--pbsk-theme-uiLabelColor);

  @include breakpoint(sm) {
    width: 22px;
  }

  @include breakpoint(lg) {
    width: 28px;
  }

  @include breakpoint(xl) {
    width: 35px;
  }
}

.iconBaseRight {
  margin-left: 4%;
}

.iconBaseLeft {
  margin-left: -4%;
}

.iconEdge {
  @include breakpoint(sm) {
    width: 19px;
  }

  @include breakpoint(lg) {
    width: 29px;
  }
}
