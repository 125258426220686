@import '@/styles/utils/functions/rem-calc';
@import '@/styles/utils/mixins/breakpoints';

.carousel {
  --slide-spacing: var(--pbsk-spacing-2);
  --slide-size: 66%;
  --slide-height: auto;
  --outline-offset: 12px;
  --button-width: 0;
  --margin-offset: calc(var(--button-width) / 2);
  --slideSpacingPlusOutlineOffset: calc(var(--outline-offset) + var(--slide-spacing));
  --scale: 1.25;
  --inverseScale: calc(1 - (((1 - var(--scale)) / var(--scale)) * -1));
  --padding-top: calc(3% + 16px);
  --padding-bottom: 13%;
  --max-width: 874px;

  position: relative;
  padding: 0;
  width: calc(100vw - var(--pbsk-client-scrollbar-width));
  margin: 0 auto;

  &.hasNav {
    --button-width: 60px;
  }

  @include breakpoint(xs) {
    --slide-spacing: var(--pbsk-spacing-3);
  }

  @include breakpoint(sm) {
    --slide-size: 50%;
    --slide-spacing: var(--pbsk-spacing-5);

    max-width: calc(100vw - var(--pbsk-client-scrollbar-width) - var(--margin-offset) * 2);
  }

  @include breakpoint(lg) {
    --slide-size: calc(var(--max-width) * .55);
    --offset-prev: 66%;
    --offset-next: -66%;
    --padding-top: 6%;
    --padding-bottom: 4%;

    max-width: var(--max-width);
    margin: auto;

    &.hasNav {
      --button-width: 72px;
    }
  }

  .carouselWrapper {
    position: relative;
    padding: var(--padding-top) 0 var(--padding-bottom) 0;
    width: calc(100% - var(--margin-offset) * 2);
    max-width: calc(100vw - var(--margin-offset) * 2);
    margin: 0 var(--margin-offset);
  }

  .container {
    position: relative;
    height: 40vw;

    @include breakpoint(sm) {
      height: 25vw;
    }

    @include breakpoint(lg) {
      height: 350px;
    }
  }

  .slide {
    --duration: .2s;
    --duration-short: calc(2 * var(--duration) / 3);

    position: absolute;
    left: 50%;
    top: 0;
    width: var(--slide-size);
    list-style: none;
    z-index: 0;
    transform: translateX(-50%);
    transition: left var(--duration-short) ease, transform var(--duration-short) ease;
    opacity: 0;

    @keyframes bumpLeft {
      0% {
        left: calc(100% - var(--slide-size));
        transform: translateX(0);
      }

      70% {
        left: 50%;
        transform: translateX(-51%);
      }

      100% {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    @keyframes bumpRight {
      0% {
        left: 0;
        transform: translateX(0);
      }

      70% {
        left: 50%;
        transform: translateX(-49%);
      }

      100% {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    @keyframes scaleBounce {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(calc(var(--scale) + .1));
      }

      100% {
        transform: scale(var(--scale));
      }
    }
  }

  .slideInner {
    transform: scale(1);
    transform-origin: 50% 40%;
    opacity: 1;
    pointer-events: none;
    transition: transform var(--duration-short) ease, opacity var(--duration) ease;

    :global(.mediaCardDetails) {
      opacity: 0;
      transform-origin: top;
    }

    :global(.mediaBadgeNew) {
      opacity: 0;
      border-width: 0;
      font-size: rem-calc(22);
      line-height: rem-calc(64);
    }
  }

  .positionPrev, .positionNext, .positionSelected, .slide:focus-within {
    opacity: 1;
  }

  .positionPrev {
    left: 0;
    transform: translateX(0);
    z-index: 1;
  }

  .positionNext {
    left: calc(100% - var(--slide-size));
    transform: translateX(0);
    z-index: 1;
  }

  .positionSelected, .slide:focus-within {
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    transition: none;
    animation-duration: var(--duration);

    .slideInner {
      transform: scale(var(--scale));
      opacity: 1;
      pointer-events: all;
      animation-name: scaleBounce;
      animation-duration: var(--duration);

      :global(.mediaBadgeNew), :global(.mediaCardDetails) {
        opacity: 1;
        transform: scale(var(--inverseScale));
        transition: opacity var(--duration-short) ease;
        transition-delay: var(--duration-short);
      }
    }
  }

  &.motionLeft .positionSelected {
    animation-name: bumpLeft;
  }

  &.motionRight .positionSelected {
    animation-name: bumpRight;
  }

  .lowerLayer {
    z-index: 0;
    opacity: 1;
    transition: left var(--duration) ease, transform var(--duration) ease, opacity var(--duration-short) steps(2, jump-end);
  }

  .navButtonContainer {
    position: absolute;
    top: calc(42.5% - 40px - var(--padding-top));
    z-index: 3;
  }

  .navRight {
    right: 0;
  }

  .navButton {
    --button-height: 112px;

    @include breakpoint(sm) {
      width: var(--button-width);
      max-width: calc(100vw - var(--margin-offset) * 2);
      height: var(--button-height);
      border-radius: 16px;

      svg {
        width: 27.5px;
      }
    }

    @include breakpoint(lg) {
      height: 144px;

      svg {
        width: 33px;
      }
    }
  }

  &.inMasthead {
    --padding-bottom: calc(10% + 25px);
    left: calc(var(--pbsk-margin) * -1);

    @include breakpoint(sm) {
      left: unset;

      .slide:not(.positionSelected) {
        :global(.aspect-video) {
          border-radius: 20px;
        }

        article {
          background-color: transparent;
        }
      }

      .slideInner {
        transform-origin: 50% 40%;
      }
    }

    @include breakpoint(lg) {
      --padding-bottom: 6%;

      .navButtonContainer {
        top: calc(40% - 40px - var(--padding-top));
      }
    }
  }
}
