@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/functions/rem-calc';
@import '@/styles/utils/mixins/aspect-ratio';

.gamesGrid {
  position: relative;
}

.gamesListItem {
  position: relative;
  grid-row: span 1;
  order: 1;

  &.twoByTwo {
    grid-row: span 2;
    grid-column: span 2;

    @include breakpoint(md) {
      grid-column: span 2;
      grid-row: span 2;
    }

    // 2x2 aspect-ratio isn't quite the same as mezzanine,
    // so override aspect ratio set in Game Card and Look Inside components.
    :global(.aspectCollageTwoByTwo), :global(.aspectCollageTwoByTwo) img {
      @include aspect-ratio(487, 283);

      div {
        @include aspect-ratio(487, 283);
      }
    }
  }

  &.oneByTwo {
    grid-row: span 2;
    grid-column: span 1;

    :global(.aspectCollageOneByTwo) {
      @include aspect-ratio(233, 275);
    }
  }

  &.twoByOne {
    grid-row: span 1;
    grid-column: span 2;
  }
}

.gamesList {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: var(--pbsk-spacing-3);

  @include breakpoint(sm) {
    gap: var(--pbsk-spacing-4);
  }

  @include breakpoint(md) {
    grid-template-columns: repeat(3, 1fr);
    gap: var(--pbsk-spacing-5);
  }

  @include breakpoint(lg) {
    grid-template-columns: repeat(4, 1fr);
  }

  &.middle {
    .gamesListItem {
      order: 1;
    }

    .twoByTwo, .twoByOne {
      order: 0;
    }

    @include breakpoint(md) {
      .gamesListItem, .twoByTwo {
        order: 0;
      }
    }
  }

  &.end {
    .gamesListItem {
      order: 1;
    }

    .twoByTwo, .twoByOne {
      order: 0;
    }

    .oneByTwo {
      order: 2;

      & ~ .gamesListItem {
        order: 3;
      }
    }

    @include breakpoint(md) {
      .gamesListItem {
        order: 0;
      }

      .gamesListItem:nth-of-type(2) {
        order: 2;
      }

      .twoByTwo {
        order: 1;

        & ~ .gamesListItem {
          order: 2;
        }
      }
    }

    @include breakpoint(lg) {
      .gamesListItem:nth-of-type(2) {
        order: 0;
      }

      .twoByTwo {
        order: 1;

        & ~ .gamesListItem {
          order: 2;
        }
      }
    }
  }
}

.iconHeaderWrapper {
  display: flex;
  align-items: center;
  column-gap: rem-calc(8);
  padding-bottom: rem-calc(12);

  h2 {
    font-size: rem-calc(20);
  }

  .iconStyling {
    width: rem-calc(40);
  }

  @include breakpoint(sm) {
    .iconStyling {
      width: rem-calc(60) !important;
    }
  }

  @include breakpoint(lg) {
    column-gap: rem-calc(12);
    padding-bottom: rem-calc(20);

    h2 {
      font-size: rem-calc(32);
    }
  }
}
