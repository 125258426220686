@import '@/styles/utils/mixins/breakpoints';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--pbsk-color-medium-gray);
  background: var(--pbsk-color-light-gray);
}

.striped {
  background: repeating-linear-gradient(
    135deg,
    var(--pbsk-color-light-gray),
    var(--pbsk-color-light-gray) 15px,
    rgb(184 184 184 / 75%) 15px,
    rgb(184 184 184 / 75%) 40px
  );
}

.title {
  position: absolute;
  top: 1%;
  left: 1%;
  font-family: var(--pbsk-font-family-headline);
  font-size: 18px;
  line-height: 1.15;
  color: white;
  letter-spacing: 0.04em;
  text-shadow:
    -1px 1px 1px var(--pbsk-color-medium-gray),
    1px -1px 1px var(--pbsk-color-medium-gray);

  @include breakpoint(sm) {
    font-size: 24px;
  }
}
